<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
                <div>
                    <h5 class="titulo_pedidos">Grupo</h5>
                    <div class="botoes_topo" v-if="this.modo=='visualizar'">
                        <Button @click="displayCadastrarCategoria = true" label="Cadastrar Categoria" class="p-button-primary"/>
                    </div>
                </div>

                <div class="grid">
                    <div class="col-8">
                        <h1>{{this.grupo.nome}}</h1>
                    </div>
                </div>
                <h5>Categorias</h5>
                

                <table class="p-datatable-table" style="overflow: scroll; display: block;overflow-x: auto;white-space: nowrap;" role="rowgroup" v-if="this.modo=='visualizar'">
                    <thead class="p-datatable-thead" role="rowgroup">
                        <Button label="Adicionar Anúncio" class="p-button-success" @click="displayAnuncio = true" v-if="this.modo=='editar'" />                            
                        <tr role="row">
                            <th style="text-align: start;padding: 0 10px;border-bottom: #000 solid;" v-for="(nomeColuna, index) in nomeColunas" :key="index" class="p-sortable-column" tabindex="0" role="cell">
                                <div class="p-column-header-content">
                                    <span v-html="nomeColuna['nome']" class="p-column-title"></span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="p-datatable-tbody" role="rowgroup" >
                        <tr v-for="(categoria, index) in grupo.categorias" :key="index" class="" role="row">
                            <td style="text-align: start;padding: 3px 10px; border-bottom: #00000050 1px solid;" v-for="(nomeColuna, index) in nomeColunas" :key="index" class=""  role="cell">{{ categoria[nomeColuna.value] }}</td>
                        </tr>
                    </tbody>
                </table>
                <Loading v-if="loading"/>
                <div v-if="!loading">

                </div>
                <div v-if="modo=='editar'">
                    <Button label="Salvar" class="p-button-success" style="margin-right:15px" @click="salvarAnuncio()"/>
                    <Button label="Cancelar Edição" class="p-button-danger" @click="modoVisualizarAnuncio()" />
                </div>
			</div>
		</div>
	</div>
    <Dialog header="Cadastrar Categoria" v-model:visible="displayCadastrarCategoria" >
        <div class="">
            <div class="inputGroup">
                <div class="inputContainer">
                    <label>Nome</label>
                    <InputText  style="width:100%" v-model="this.nome_categoria"/>
                </div>
            </div>
            <div style="text-align:center; width:100%; margin-top:10px;">                
                <Button label="Salvar" class="p-button-success" @click="salvarCategoria()"/> 
                <Button label="Fechar" class="p-button-danger" @click="displayCadastrarCategoria = false"/> 
            </div>
        </div>
    </Dialog>
    <Toast position="top-left" />

</template>

<script>

import Api from '../service/api';
import Loading from '../components/Loading.vue';

	export default {

    data() {
        return {
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
			msg_erro:'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            api: "",
            loading: true,
            modo:"visualizar",//visualizar ou editar
            grupo: {},
            id_grupo:0,
            nome_categoria:"",
            pagina: "1",
            quantidadePorPagina: "10",
            primeiroItemDaPagina: 1,
            ordenar: "id",
            tipoOrdenar: "DESC",
            qtd_atributos:1,
            totalLinhas: null,
            displayCadastrarCategoria:false,
            quantidades: ["10", "20", "50"],
            status: [
                { name:'Ativo', code:'ATIVO' },
                { name: 'Inativo', code: 'INATIVO' }
            ],
            nomeColunas: [
                { nome: "Nome", value: "nome" }
            ],
            nomeColunasAtributo: [
                { nome: "Nome", value: "nome_categoria" }
            ],
            produtosAdicionar: [
                { nome: "" },
                { descricao: "" },
                { sku: "" },
                { ean: "" },
                { preco_normal: "" },
                { preco_desconto: "" },
                { altura: "" },
                { largura: "" },
                { profundidade: "" },
                { peso: "" },
                { estoque: "" },
                { video: "" },
                { status: ""}
            ],
            mostrarAdicionar: false,
            nome_atributo:[],
            valor_atributo:[],
        };
    },
    produtosService: null,
    quantidadePaginas: 1,
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.empresa_id = this.empresa.id
        this.api = new Api();
        this.api.qtdAnuncios().then(data => { this.totalLinhas = data.data.quantidade
        }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
    },
    mounted() {
        if(this.$router.currentRoute.value.params.id != undefined){
            this.grupo.id = this.$router.currentRoute.value.params.id
            this.carregarGrupo();
        }else{
            this.grupo.id = 0
        }

    },
    methods: {
        carregarGrupo() {
            this.loading = true;
            this.api.carregarGrupo(this.token,this.$router.currentRoute.value.params.id,this.empresa_id).then(data => {
                this.grupo = data.data.resposta
                this.loading = false;
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
            this.loading = false;
        },
        modoEditarAnuncio(){
            this.modo = "editar"
        },
        modoVisualizarAnuncio(){
            this.modo = "visualizar"
        },
        adicionarAtributo(){
            this.qtd_atributos = this.qtd_atributos + 1;
        },
        salvarCategoria(){
            this.api.saveCategoria(this.nome_categoria,this.grupo.id,1).then(data =>{
                console.log(data)
                this.nome_categoria = ""
                this.carregarGrupo()
                this.displayCadastrarCategoria = false;
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        paginaAtual(pagina) {
            this.pagina = pagina;
            // this.primeiroItemDaPagina = pagina * this.quantidadePorPagina + 1 - this.quantidadePorPagina;
            this.listarAnuncios();
        },
        proximaPagina() {
            if (this.quantidadePaginas > this.pagina)
                this.paginaAtual(this.pagina + 1);
                this.listarAnuncios();
        },
        anteriorPagina() {
            if (this.pagina > 1) {
                this.paginaAtual(this.pagina - 1);
                this.listarAnuncios();
            }
        },
        primeiraPagina() {
            this.paginaAtual(1);
            this.listarAnuncios();
        },
        ultimaPagina() {
            this.paginaAtual(this.quantidadePaginas);
            this.listarAnuncios();
        },
        adicionarAnuncio() {
            this.$toast.add({ severity: "success", summary: "Sucesso", detail: "Produto Adicionado", life: 3000 });
            this.limparFormAnuncio();
        },
        limparFormAnuncio() {
            for (let field in this.produtosAdicionar) {
                this.anunciosAdicionar[field] = "";
            }
            this.mostrarAdicionar = false;
        }
    },
    components: { Loading }
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
<style scoped lang="css">
input{
    margin: 10px;
}
.marketplace{
    width:33%;
    display: inline-block;
    padding:15px;
}

.card_marketplace{
    width:100%;
    padding:14px;

    border-radius: 5px;
    border: #000 solid 1px;
}

.titulo_marketplace{
    font-size: 14pt;
    font-weight: bold;
}

.informacoes_anuncios .numero{
    font-size: 20pt;
    font-weight: bold;
    margin-bottom:0;
    margin-top:10px;
}
.informacoes_anuncios .titulo_informacoes{
    font-size: 12pt;
    font-weight: bold;
}
input{
    margin: 0px;
    width:100%
}
.botoes_topo{
    display: inline-block;
    width: 50%;
    text-align:right
}
.titulo_pedidos{
    display: inline-block; 
    width: 50%;
}
label{
    font-size: 12pt;
    font-weight: bold;
}

</style>
